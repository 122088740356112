import { Dede } from ".";
import { getGameInitData, getGameSpin, getGameStats } from "./service";
import { IGameSpinResponse } from "./service/types";

export class ServiceManager {
  balancePullingInterval?: NodeJS.Timeout;
  simulateSpinResponse?: IGameSpinResponse;
  constructor(private game: Dede) {}

  async initGame() {
    const initData = await getGameInitData(this.game.id);
    this.game.config = initData.config;
    this.game.balance = initData.stats.balance;
    this.game.stake = initData.config.limits.defaultBet;
    this.game.index = initData.index;
    this.game.reelsManager.loadNumbers(initData.outcomes[0]?.symbols, true);
    console.log("Game initialized with config: ", this.game);
    clearInterval(this.balancePullingInterval!);
    this.balancePullingInterval = setInterval(() => {
      if (this.game.isRunning) return;
      this.getStats();
    }, 5000);
  }

  async getStats() {
    const stats = await getGameStats(this.game.id);
    // console.log("Stats received: ", stats);
    this.game.balance = stats.stats.balance;
  }

  loadSimulateResponse(response: string) {
    const responseParsed = JSON.parse(response.trim());
    this.simulateSpinResponse = responseParsed;
  }

  async getSpin(buyFreeSpins: boolean = false, anteBetEnabled: boolean = false) {
    const spinResult = this.simulateSpinResponse || (await getGameSpin(this.game, buyFreeSpins));
    if (this.simulateSpinResponse) {
      this.simulateSpinResponse = undefined;
    }
    this.game.outcomes = spinResult.outcomes.map((outcome, index) => {
      outcome.isLastOutcome = index === spinResult.outcomes.length - 1;
      outcome.tumbleWinAmount = outcome.isLastOutcome ? spinResult.winAmount : outcome.runningTotal;
      outcome.totalWinAmount = outcome.tumbleWinAmount;
      return outcome;
    });

    this.game.index = spinResult.index;
    this.game.freeSpinManager.processSpinResponse(spinResult);
  }
}
