import { Flex, Modal, Switch, Tabs } from "antd";
import React, { memo, useEffect, useState } from "react";
import { Game } from "../../lib/games/game";
import { Assets } from "pixi.js";
import { REELS_COUNT, SYMBOLS_PER_REEL } from "../../lib/games/dede/resources/constants";
import styles from "./index.module.css";

const InfoScreen: React.FC<{ game: Game; visible: boolean; onClose: () => void }> = ({ game, visible, onClose }) => {
  const [soundEnabled, setSoundEnabled] = useState(game.soundEnabled);
  const [stake, setStake] = useState(game.stake);

  useEffect(() => {
    const stakeListener = (stake: number) => {
      setStake(stake);
    };
    game.addStakeChangeListener(stakeListener);
    const listener = (enabled: boolean) => {
      setSoundEnabled(enabled);
    };
    game.addSoundChangeListener(listener);

    return () => {
      game.removeStakeChangeListener(stakeListener);
      game.removeSoundChangeListener(listener);
    };
  }, [game]);

  const renderPayTableCell = (symbol: number, isScatter?: boolean) => {
    const symbolPayouts = game.config.payTable.symbolPayouts.find((s) => s.symbol === symbol);
    if (!symbolPayouts) return null;
    const payoutArray = Object.entries(symbolPayouts.payouts).map(([key, value]) => {
      return { key, value };
    });
    return (
      <div style={{ textAlign: "center", width: "fit-content" }} key={symbol}>
        <img src={Assets.get("s" + symbol).label} style={{ width: 50, height: 50 }} alt="symbol" />
        <div className={styles.payCellInfo}>
          <div style={{ textAlign: "left", fontSize: 12 }}>
            {payoutArray.map((payout, i) => (
              <div key={payout.key}>
                {payout.key} - {payoutArray[i + 1]?.key || SYMBOLS_PER_REEL * REELS_COUNT} : ${payout.value * stake}
              </div>
            ))}
          </div>
          {isScatter && (
            <div style={{ fontSize: 14, textAlign: "left" }}>
              <div>This is SCATTER symbol</div>
              <div>SCATTER is present all reels</div>
              <div>SCATTER pays on any position</div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      maskClosable={false}
      maskAnimation={false}
      mask={false}
    >
      <Tabs
        items={[
          {
            key: "1",
            label: "Pay Table",
            children: (
              <div style={{ height: 400, overflow: "auto" }}>
                <div className={styles.payTableRow}>
                  {Array.from({ length: 9 }, (_, i) => i + 1).map((symbol) => renderPayTableCell(symbol))}
                </div>
                <div className={styles.payTableRow}>{renderPayTableCell(10, true)}</div>
              </div>
            ),
          },
          { key: "2", label: "Game Info", children: <div style={{ height: 400 }}>Game Info</div> },
          {
            key: "3",
            label: "Settings",
            children: (
              <Flex style={{ height: 400 }} justify="space-between">
                <div>Audio</div>
                <Switch
                  checked={soundEnabled}
                  onChange={(checked) => {
                    game.soundEnabled = checked;
                  }}
                />
              </Flex>
            ),
          },
        ]}
      />
    </Modal>
  );
};

export default memo(InfoScreen);
