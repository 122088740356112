import { Graphics, Sprite, Text, Texture } from "pixi.js";
import { Dede } from ".";
import { IGameSpinResponse } from "./service/types";

export class FreeSpinManager {
  private freeSpins: any[] = [];
  private numberOfFreeSpinsTriggered = 0;
  popupContainer?: Graphics;
  totalMultiplierContainer?: Graphics;
  totalMultiplierText?: Text;
  resultContainer?: Graphics;

  remainingFreeSpinContainer?: Graphics;
  remainingFreeSpinText?: Text;
  startFreeSpinButton?: Sprite;
  freeSpinIndex = 0;

  constructor(private game: Dede) {
    game.addFreeSpinChangeListener(() => {
      this.updateTotalMultiplier();
    });
  }

  processSpinResponse(spinResponse: IGameSpinResponse) {
    if (spinResponse.freeSpinsTriggered) {
      const mountPopupCallback = () => {
        this.game.freeSpinTotalMultiplier = 1;
        this.game.freeSpinActive = true;
        this.numberOfFreeSpinsTriggered = spinResponse.numberOfFreeSpinsTriggered;
        this.mountFreeSpinStartPopup();
        this.game.removeFallCompleteListener(mountPopupCallback);
      };
      this.game.addFallCompleteListener(mountPopupCallback);
    } else if (spinResponse.freeSpins.length > 0) {
      this.freeSpinIndex = 0;
      this.game.freeSpinWinAmount = 0;

      spinResponse.freeSpins.forEach((spin, x) => {
        const prevSpin = spinResponse.freeSpins[x - 1];
        spin.outcomes.forEach((outcome, index) => {
          outcome.isLastOutcome = index === spin.outcomes.length - 1;
          const spinData: any = {
            ...spin,
          };
          delete spinData.outcomes;
          outcome.spinData = spinData;
          outcome.isFreeSpin = true;
          outcome.totalWinAmount = outcome.isLastOutcome
            ? spin.runningTotal
            : (prevSpin?.runningTotal || 0) + outcome.runningTotal;
          outcome.tumbleWinAmount = outcome.isLastOutcome ? spin.winAmount : outcome.runningTotal;

          outcome.isLastFreeSpinOutcome = x === spinResponse.freeSpins.length - 1 && index === spin.outcomes.length - 1;
        });
      });
      this.freeSpins = spinResponse.freeSpins;
      this.numberOfFreeSpinsTriggered = spinResponse.freeSpins.length;

      const spinNext = () => {
        console.log("spinNext", this.freeSpinIndex, this.freeSpins.length);
        this.game.runFreeSpinReels(this.freeSpins[this.freeSpinIndex].outcomes);
        this.freeSpinIndex++;
      };
      spinNext();
      this.mountRemainingFreeSpinsInfo();
      this.mountTotalMultiplierInfo();

      const handleSpinComplete = (spinWinAmount: number, totalWinAmount: number) => {
        console.log("handleSpinComplete", spinWinAmount, this.freeSpinIndex, this.freeSpins.length);
        this.game.freeSpinWinAmount += spinWinAmount;
        if (this.freeSpinIndex < this.freeSpins.length) {
          spinNext();
          this.updateRemainingFreeSpins();
        } else {
          this.game.removeFallCompleteListener(handleSpinComplete);
          this.unmountRemainingFreeSpinsInfo();
          this.unmountTotalMultiplierInfo();
          this.game.freeSpinActive = false;
          this.mountFreeSpinResultPopup();
        }
      };
      this.game.addSpinCompleteListener(handleSpinComplete);
    }
  }
  mountRemainingFreeSpinsInfo() {
    console.log("mountRemainingFreeSpinsPopup");
    const graphics = new Graphics();

    const text1 = new Text();
    text1.text = "Free Spins";
    text1.style = { fill: 0xffffff, fontSize: 10 };
    text1.x = window.innerWidth - (text1.width + 4);
    text1.y = 202;

    let totalHeight = text1.height + 2;

    const text2 = new Text();
    text2.text = "Left";
    text2.style = { fill: 0xffffff, fontSize: 10 };
    text2.x = window.innerWidth - (text2.width + 4 + (text1.width - text2.width) / 2);
    text2.y = 202 + totalHeight;

    totalHeight += text2.height + 2;

    const text3 = new Text();
    text3.text = this.numberOfFreeSpinsTriggered - this.freeSpinIndex + "";
    text3.style = { fill: 0xffffff, fontSize: 12 };
    text3.x = window.innerWidth - (text3.width + 4 + (text1.width - text3.width) / 2);
    text3.y = 202 + totalHeight;

    totalHeight += text3.height + 2;

    const rect = graphics.rect(window.innerWidth - (text1.width + 6), 200, text1.width + 4, totalHeight + 4);
    rect.fill(0x9d34aa);
    rect.alpha = 1;

    rect.addChild(text1);
    rect.addChild(text2);
    rect.addChild(text3);
    //create button to start free spin

    this.game.app.stage.addChild(graphics);
    this.remainingFreeSpinContainer = graphics;
    this.remainingFreeSpinText = text3;
  }
  updateRemainingFreeSpins() {
    if (this.remainingFreeSpinText) {
      const remainingFreeSpins = this.numberOfFreeSpinsTriggered - this.freeSpinIndex;
      this.remainingFreeSpinText.text = remainingFreeSpins + "";
      if (remainingFreeSpins === 9) this.remainingFreeSpinText.x += 6;
    }
  }
  unmountRemainingFreeSpinsInfo() {
    this.remainingFreeSpinContainer?.destroy();
  }

  mountTotalMultiplierInfo() {
    console.log("mountTotalMultiplierInfo");
    const graphics = new Graphics();

    const text1 = new Text();
    text1.text = "Total";
    text1.style = { fill: 0xffffff, fontSize: 10 };
    text1.x = window.innerWidth - (text1.width + 4);
    text1.y = 152;

    let totalHeight = text1.height + 2;

    const text3 = new Text();
    text1.text = "Multiplier";
    text1.style = { fill: 0xffffff, fontSize: 10 };
    text1.x = window.innerWidth - (text1.width + 4);
    text1.y = 152;

    totalHeight = text3.height + 2;

    const text2 = new Text();
    text2.text = this.game.freeSpinTotalMultiplier + "";
    text2.style = { fill: 0xffffff, fontSize: 12 };
    text2.x = window.innerWidth - (text2.width + 4 + (text1.width - text2.width) / 2);
    text2.y = 152 + totalHeight;

    totalHeight += text2.height + 2;

    const rect = graphics.rect(window.innerWidth - (text1.width + 6), 150, text1.width + 4, totalHeight + 4);
    rect.fill(0x9d34aa);
    rect.alpha = 1;

    rect.addChild(text1);
    rect.addChild(text2);
    //create button to start free spin

    this.game.app.stage.addChild(graphics);
    this.totalMultiplierContainer = graphics;
    this.totalMultiplierText = text2;
  }

  unmountTotalMultiplierInfo() {
    this.totalMultiplierContainer?.destroy();
  }

  updateTotalMultiplier() {
    console.log("updateTotalMultiplier", this.game.freeSpinTotalMultiplier);
    if (this.totalMultiplierText) {
      this.totalMultiplierText.text = this.game.freeSpinTotalMultiplier + "";
    }
  }

  mountFreeSpinResultPopup() {
    console.log("mountFreeSpinResultPopup");
    const graphics = new Graphics();
    const rect = graphics.rect(20, 200, window.innerWidth - 40, 200);
    rect.fill(0x9d34aa);
    rect.alpha = 1;
    const text = new Text();
    if (this.game.freeSpinWinAmount) text.text = "You won " + this.game.freeSpinWinAmount + "!";
    else text.text = "You won nothing!";
    text.style = { fill: 0xffffff, fontSize: 20 };
    text.x = window.innerWidth / 2 - text.width / 2;
    text.y = 300;
    rect.addChild(text);

    const okButton = this.initOkButton();
    graphics.addChild(okButton);
    this.game.app.stage.addChild(graphics);
    this.resultContainer = graphics;
  }

  initStartButton() {
    const buttonTexture = Texture.from("startButton"); // Use any button image
    const button = new Sprite(buttonTexture);

    button.interactive = true;
    button.cursor = "pointer"; // Set cursor to pointer on hover
    button.width = 100;
    button.height = 40;
    button.anchor.set(0.5);
    button.x = window.innerWidth / 2;
    button.y = 350;
    button.zIndex = 100;

    button.on("pointerdown", () => {
      button.scale.set(button.scale.x * 0.9);
      button.tint = 0xaaaaaa;
      console.log("pointerdown!");
    });
    button.on("pointerup", () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
      this.game.runReels(false);
      this.unmountFreeSpinPopup();
    });
    button.on("pointerupoutside", () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
    });
    this.startFreeSpinButton = button;
    console.log("initButton", this.startFreeSpinButton);
  }
  initOkButton() {
    const buttonTexture = Texture.from("okButton"); // Use any button image
    const button = new Sprite(buttonTexture);

    button.interactive = true;
    button.cursor = "pointer"; // Set cursor to pointer on hover
    button.width = 60;
    button.height = 60;
    button.anchor.set(0.5);
    button.x = window.innerWidth / 2;
    button.y = 350;
    button.zIndex = 100;

    button.on("pointerdown", () => {
      button.scale.set(button.scale.x * 0.9);
      button.tint = 0xaaaaaa;
      console.log("pointerdown!");
    });
    button.on("pointerup", () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
      this.resultContainer?.destroy();
    });
    button.on("pointerupoutside", () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
    });
    return button;
  }
  mountFreeSpinStartPopup() {
    console.log("mountFreeSpinUI");
    const graphics = new Graphics();
    const rect = graphics.rect(20, 200, window.innerWidth - 40, 200);
    rect.fill(0x9d34aa);
    rect.alpha = 1;
    const text = new Text();
    text.text = "You won " + this.numberOfFreeSpinsTriggered + " free spins!";
    text.style = { fill: 0xffffff, fontSize: 20 };
    text.x = window.innerWidth / 2 - text.width / 2;
    text.y = 300;
    rect.addChild(text);
    //create button to start free spin
    this.initStartButton();
    graphics.addChild(this.startFreeSpinButton!);
    this.game.app.stage.addChild(graphics);
    this.popupContainer = graphics;
  }
  unmountFreeSpinPopup() {
    this.popupContainer?.destroy();
  }
}
