import { IGameOutcome } from "./dede/service/types";
import {
  AnteBetChangeListener,
  AssetsLoadedListener,
  AutoPlayChangeListener,
  BalanceChangeListener,
  ClickListener,
  DeviceType,
  DeviceTypeChangeListener,
  ExplodeListener,
  FallCompleteListener,
  FreeSpinChangeListener,
  GameConfigChangeListener,
  GameLoadedListener,
  GameStateChangeListener,
  HistoryChangeListener,
  IFreeSpinStatus,
  IGameConfig,
  ISpinHistoryElement,
  SelectListener,
  SoundChangeListener,
  SpinCompleteListener,
  SpinListener,
  SpinWinAmountChangeListener,
  StakeChangeListener,
  TumbleListener,
  WinAmountChangeListener,
} from "./types";

export class Game {
  id: string = "";
  private _freeSpinChangeListeners: FreeSpinChangeListener[] = [];
  private _spinListeners: SpinListener[] = [];
  private _selectListeners: SelectListener[] = [];
  private _explodeListeners: ExplodeListener[] = [];
  private _clickListeners: ClickListener[] = [];

  private _soundEnabled = false;
  private _soundChangeListeners: SoundChangeListener[] = [];

  private _freeSpinWinAmount = 0;
  private _freeSpinTotalMultiplier = 0;
  private _freeSpinActive = false;

  private _balance: number = 0;
  private _balanceChangeListeners: BalanceChangeListener[] = [];

  private _winAmount: number = 0;
  private _winAmountChangeListeners: WinAmountChangeListener[] = [];

  private _spinWinAmount: number = 0;
  private _spinWinAmountChangeListeners: SpinWinAmountChangeListener[] = [];

  private _stake: number = 0;
  private _stakeChangeListeners: StakeChangeListener[] = [];

  private _deviceType: DeviceType = "desktop";
  private _deviceTypeChangeListeners: DeviceTypeChangeListener[] = [];

  private _loaded = false;
  private _loadedListeners: GameLoadedListener[] = [];

  private _isRunning: boolean = false;
  private _gameStateChangeListeners: GameStateChangeListener[] = [];

  private _anteBetActive: boolean = false;
  private _anteBetChangeListeners: AnteBetChangeListener[] = [];

  private _tumbleListeners: TumbleListener[] = [];
  private _assetsLoadedListeners: AssetsLoadedListener[] = [];
  private _fallCompleteListeners: FallCompleteListener[] = [];
  private _spinCompleteListeners: SpinCompleteListener[] = [];

  private _history: ISpinHistoryElement[] = [];
  private _historyChangeListeners: HistoryChangeListener[] = [];

  private _autoPlayCount: number = 0;
  private _autoPlayCountChangeListeners: AutoPlayChangeListener[] = [];

  private _config: IGameConfig = {
    payTable: {
      symbolPayouts: [],
    },
    limits: {
      defaultBet: 0,
      legalBets: [],
      autoBets: [],
      winSizes: [],
    },
    freeSpinBuyMultiplier: 0,
    scatterSymbol: 0,
  };
  private _gameConfigChangeListeners: GameConfigChangeListener[] = [];

  index = 0;
  paused = false;
  initialSpinDone = false;

  constructor() {
    if (this.constructor === Game) {
      throw new Error("Cannot instantiate an abstract class.");
    }

    window.addEventListener("resize", () => {
      const newDeviceType = this.getDeviceType();
      if (newDeviceType !== this._deviceType) {
        this._deviceType = newDeviceType;
        this._triggerDeviceTypeChangeEvent(newDeviceType);
      }
    });
    setTimeout(() => {
      this._triggerDeviceTypeChangeEvent(this.getDeviceType());
    }, 500);
    this.id = Math.random().toString(36).substring(2, 9);
    this.addAutoPlayChangeListener((autoPlayCount, prev) => {
      if (prev === 0 && autoPlayCount) {
        this.runReels();
      }
    });
  }

  // Abstract method
  runReels(buyFreeSpins: boolean = false): void {
    throw new Error("Abstract method 'runReels' must be implemented.");
  }

  simulate(response: string): void {
    throw new Error("Abstract method 'simulate' must be implemented.");
  }

  onGoldenBetClick(): void {
    throw new Error("Abstract method 'onGoldenBetClick' must be implemented.");
  }

  public get soundEnabled(): boolean {
    return this._soundEnabled;
  }

  public set soundEnabled(value: boolean) {
    this._soundEnabled = value;
    this._triggerSoundChangeEvent(value);
  }

  public get history(): ISpinHistoryElement[] {
    return this._history;
  }

  public set history(value: ISpinHistoryElement[]) {
    this._history = value;
    this._triggerHistoryChangeEvent(value);
  }

  public get balance(): number {
    return this._balance;
  }

  public set balance(value: number) {
    this._balance = value;
    this._triggerBalanceChangeEvent(value);
  }

  public get loaded(): boolean {
    return this._loaded;
  }

  public set loaded(value: boolean) {
    if (value) {
      this._loaded = value;
      this._triggerGameLoadedEvent();
    }
  }

  public get autoPlayCount(): number {
    return this._autoPlayCount;
  }

  public set autoPlayCount(value: number) {
    const prev = this._autoPlayCount;
    this._autoPlayCount = value;
    console.log("autoPlayCount", value, prev);
    if (prev !== value) this._triggerAutoPlayChangeEvent(value, prev);
  }

  public get winAmount(): number {
    return this._winAmount;
  }

  public set winAmount(value: number) {
    console.log("winAmount", value);
    const prev = this._winAmount;
    this._winAmount = value;
    this._triggerWinAmountChangeEvent(value, Math.min(value - prev, 0));
  }

  public get spinWinAmount(): number {
    return this._spinWinAmount;
  }

  public set spinWinAmount(value: number) {
    this._spinWinAmount = value;
    this._triggerSpinWinAmountChangeEvent(value);
  }

  public get freeSpinWinAmount(): number {
    return this._freeSpinWinAmount;
  }

  public set freeSpinWinAmount(value: number) {
    this._freeSpinWinAmount = value;
    this.onFreeSpinChange();
  }

  public get freeSpinTotalMultiplier(): number {
    return this._freeSpinTotalMultiplier;
  }

  public set freeSpinTotalMultiplier(value: number) {
    const prev = this._freeSpinTotalMultiplier;
    this._freeSpinTotalMultiplier = value;
    if (prev !== value) this.onFreeSpinChange();
  }

  public get freeSpinActive(): boolean {
    return this._freeSpinActive;
  }

  public set freeSpinActive(value: boolean) {
    this._freeSpinActive = value;
    this.onFreeSpinChange();
  }

  //free spin listeners
  public addFreeSpinChangeListener(listener: FreeSpinChangeListener): void {
    this._freeSpinChangeListeners.push(listener);
  }
  public removeFreeSpinChangeListener(listener: FreeSpinChangeListener): void {
    this._freeSpinChangeListeners = this._freeSpinChangeListeners.filter((l) => l !== listener);
  }
  private _triggerFreeSpinChangeEvent(freeSpin: IFreeSpinStatus): void {
    for (const listener of this._freeSpinChangeListeners) {
      listener(freeSpin);
    }
  }

  public get stake(): number {
    return this._stake;
  }

  public set stake(value: number) {
    this._stake = value;
    this._triggerStakeChangeEvent(value);
  }

  public get isRunning(): boolean {
    return this._isRunning;
  }

  protected set isRunning(value: boolean) {
    this._isRunning = value;
    this._triggerGameStateChangeEvent(value);
  }

  public get anteBetActive(): boolean {
    return this._anteBetActive;
  }

  public set anteBetActive(value: boolean) {
    const prev = this._anteBetActive;
    this._anteBetActive = value;
    if (prev !== value) {
      this._triggerAnteBetChangeEvent(value);
    }
  }

  public get config(): IGameConfig {
    return this._config;
  }

  public set config(value: IGameConfig) {
    if (JSON.stringify(this._config) === JSON.stringify(value)) return;
    this._config = value;
    this._triggerConfigChangeEvent(value);
  }

  //spin listeners
  public addSpinListener(listener: SpinListener): void {
    this._spinListeners.push(listener);
  }
  public removeSpinListener(listener: SpinListener): void {
    this._spinListeners = this._spinListeners.filter((l) => l !== listener);
  }
  private _triggerSpinEvent(buyFreeSpin?: boolean): void {
    for (const listener of this._spinListeners) {
      listener(buyFreeSpin);
    }
  }

  //click listeners
  public addClickListener(listener: ClickListener): void {
    this._clickListeners.push(listener);
  }
  public removeClickListener(listener: ClickListener): void {
    this._clickListeners = this._clickListeners.filter((l) => l !== listener);
  }
  private _triggerClickEvent(): void {
    for (const listener of this._clickListeners) {
      listener();
    }
  }

  //fall complete listeners
  public addFallCompleteListener(listener: FallCompleteListener): void {
    this._fallCompleteListeners.push(listener);
  }
  public removeFallCompleteListener(listener: FallCompleteListener): void {
    this._fallCompleteListeners = this._fallCompleteListeners.filter((l) => l !== listener);
  }
  private _triggerFallCompleteEvent(winAmount: number, multiplier: number, outcome?: IGameOutcome): void {
    for (const listener of this._fallCompleteListeners) {
      listener(winAmount, multiplier, outcome);
    }
  }

  //fall complete listeners
  public addSpinCompleteListener(listener: SpinCompleteListener): void {
    this._spinCompleteListeners.push(listener);
  }
  public removeSpinCompleteListener(listener: SpinCompleteListener): void {
    this._spinCompleteListeners = this._spinCompleteListeners.filter((l) => l !== listener);
  }
  private _triggerSpinCompleteEvent(tumbleWinAmount: number, totalWinAmount: number): void {
    for (const listener of this._spinCompleteListeners) {
      listener(tumbleWinAmount, totalWinAmount);
    }
  }

  //select listeners
  public addSelectListener(listener: SelectListener): void {
    this._selectListeners.push(listener);
  }
  public removeSelectListener(listener: SelectListener): void {
    this._selectListeners = this._selectListeners.filter((l) => l !== listener);
  }
  private _triggerSelectEvent(): void {
    for (const listener of this._selectListeners) {
      listener();
    }
  }

  //explode listeners
  public addExplodeListener(listener: ExplodeListener): void {
    this._explodeListeners.push(listener);
  }
  public removeExplodeListener(listener: ExplodeListener): void {
    this._explodeListeners = this._explodeListeners.filter((l) => l !== listener);
  }
  private _triggerExplodeEvent(): void {
    for (const listener of this._explodeListeners) {
      listener();
    }
  }

  //sound change listeners
  public addSoundChangeListener(listener: SoundChangeListener): void {
    this._soundChangeListeners.push(listener);
  }

  public removeSoundChangeListener(listener: SoundChangeListener): void {
    this._soundChangeListeners = this._soundChangeListeners.filter((l) => l !== listener);
  }

  private _triggerSoundChangeEvent(enabled: boolean): void {
    for (const listener of this._soundChangeListeners) {
      listener(enabled);
    }
  }

  //history change listeners
  public addHistoryListener(listener: HistoryChangeListener): void {
    this._historyChangeListeners.push(listener);
  }

  public removeHistoryChangeListener(listener: HistoryChangeListener): void {
    this._historyChangeListeners = this._historyChangeListeners.filter((l) => l !== listener);
  }

  private _triggerHistoryChangeEvent(newHistory: ISpinHistoryElement[]): void {
    for (const listener of this._historyChangeListeners) {
      listener(newHistory);
    }
  }

  //balance change listeners
  public addBalanceChangeListener(listener: BalanceChangeListener): void {
    this._balanceChangeListeners.push(listener);
  }

  public removeBalanceChangeListener(listener: BalanceChangeListener): void {
    this._balanceChangeListeners = this._balanceChangeListeners.filter((l) => l !== listener);
  }

  private _triggerBalanceChangeEvent(newBalance: number): void {
    for (const listener of this._balanceChangeListeners) {
      listener(newBalance);
    }
  }

  //autoplay change listeners
  public addAutoPlayChangeListener(listener: AutoPlayChangeListener): void {
    this._autoPlayCountChangeListeners.push(listener);
  }

  public removeAutoPlayChangeListener(listener: AutoPlayChangeListener): void {
    this._autoPlayCountChangeListeners = this._autoPlayCountChangeListeners.filter((l) => l !== listener);
  }

  private _triggerAutoPlayChangeEvent(newAutoPlayCount: number, prev: number): void {
    for (const listener of this._autoPlayCountChangeListeners) {
      listener(newAutoPlayCount, prev);
    }
  }

  //win amount change listeners
  public addWinAmountChangeListener(listener: WinAmountChangeListener): void {
    this._winAmountChangeListeners.push(listener);
  }

  public removeWinAmountChangeListener(listener: WinAmountChangeListener): void {
    this._winAmountChangeListeners = this._winAmountChangeListeners.filter((l) => l !== listener);
  }

  private _triggerWinAmountChangeEvent(newWinAmount: number, diff: number): void {
    for (const listener of this._winAmountChangeListeners) {
      listener(newWinAmount, diff);
    }
  }

  //each spin win amount change listeners
  public addSpinWinAmountChangeListener(listener: SpinWinAmountChangeListener): void {
    this._spinWinAmountChangeListeners.push(listener);
  }

  public removeSpinWinAmountChangeListener(listener: SpinWinAmountChangeListener): void {
    this._spinWinAmountChangeListeners = this._spinWinAmountChangeListeners.filter((l) => l !== listener);
  }

  private _triggerSpinWinAmountChangeEvent(newSpinWinAmount: number): void {
    for (const listener of this._spinWinAmountChangeListeners) {
      listener(newSpinWinAmount);
    }
  }

  //stake change listeners
  public addStakeChangeListener(listener: StakeChangeListener): void {
    this._stakeChangeListeners.push(listener);
  }

  public removeStakeChangeListener(listener: StakeChangeListener): void {
    this._stakeChangeListeners = this._stakeChangeListeners.filter((l) => l !== listener);
  }

  private _triggerStakeChangeEvent(newStake: number): void {
    for (const listener of this._stakeChangeListeners) {
      listener(newStake);
    }
  }

  //deviceType change listeners
  public addDeviceTypeChangeListener(listener: DeviceTypeChangeListener): void {
    this._deviceTypeChangeListeners.push(listener);
  }

  public removeDeviceTypeChangeListener(listener: DeviceTypeChangeListener): void {
    this._deviceTypeChangeListeners = this._deviceTypeChangeListeners.filter((l) => l !== listener);
  }

  private _triggerDeviceTypeChangeEvent(newDeviceType: DeviceType): void {
    for (const listener of this._deviceTypeChangeListeners) {
      listener(newDeviceType);
    }
  }

  //loaded listeners
  public addGameLoadedListener(listener: GameLoadedListener): void {
    this._loadedListeners.push(listener);
  }

  public removeGameLoadedListener(listener: GameLoadedListener): void {
    this._loadedListeners = this._loadedListeners.filter((l) => l !== listener);
  }

  private _triggerGameLoadedEvent(): void {
    for (const listener of this._loadedListeners) {
      listener();
    }
  }

  //game state change listeners

  public addGameStateChangeListener(listener: GameStateChangeListener): void {
    this._gameStateChangeListeners.push(listener);
  }

  public removeGameStateChangeListener(listener: GameStateChangeListener): void {
    this._gameStateChangeListeners = this._gameStateChangeListeners.filter((l) => l !== listener);
  }

  private _triggerGameStateChangeEvent(running: boolean): void {
    for (const listener of this._gameStateChangeListeners) {
      listener(running);
    }
  }

  //ante bet change listeners
  public addAnteBetChangeListener(listener: AnteBetChangeListener): void {
    this._anteBetChangeListeners.push(listener);
  }

  public removeAnteBetChangeListener(listener: AnteBetChangeListener): void {
    this._anteBetChangeListeners = this._anteBetChangeListeners.filter((l) => l !== listener);
  }

  private _triggerAnteBetChangeEvent(isAnteBetActive: boolean): void {
    for (const listener of this._anteBetChangeListeners) {
      listener(isAnteBetActive);
    }
  }

  //config change listeners

  public addConfigChangeListener(listener: GameConfigChangeListener): void {
    this._gameConfigChangeListeners.push(listener);
  }

  public removeConfigChangeListener(listener: GameConfigChangeListener): void {
    this._gameConfigChangeListeners = this._gameConfigChangeListeners.filter((l) => l !== listener);
  }

  private _triggerConfigChangeEvent(config: IGameConfig): void {
    for (const listener of this._gameConfigChangeListeners) {
      listener(config);
    }
  }
  //tumble listeners

  public addTumbleListener(listener: TumbleListener): void {
    this._tumbleListeners.push(listener);
  }

  public removeTumbleListener(listener: TumbleListener): void {
    this._tumbleListeners = this._tumbleListeners.filter((l) => l !== listener);
  }

  private _triggerTumbleEvent(outcome: IGameOutcome): void {
    for (const listener of this._tumbleListeners) {
      listener(outcome);
    }
  }

  //assetsLoaded listeners
  public addAssetsLoadedListener(listener: AssetsLoadedListener): void {
    this._assetsLoadedListeners.push(listener);
  }

  public removeAssetsLoadedListener(listener: AssetsLoadedListener): void {
    this._assetsLoadedListeners = this._assetsLoadedListeners.filter((l) => l !== listener);
  }

  private _triggerAssetsLoadedEvent(): void {
    for (const listener of this._assetsLoadedListeners) {
      listener();
    }
  }

  //methods

  tumble(outcome: IGameOutcome) {
    this._triggerTumbleEvent(outcome);
  }

  onMountDone() {
    this.loaded = true;
    this._triggerDeviceTypeChangeEvent(this.getDeviceType());
  }

  onExplode = () => {
    this._triggerExplodeEvent();
  };

  onSelect = () => {
    this._triggerSelectEvent();
  };

  onClick = () => {
    this._triggerClickEvent();
  };

  onFreeSpinChange = () => {
    this._triggerFreeSpinChangeEvent({
      active: this._freeSpinActive,
      totalWinning: this._freeSpinWinAmount,
      totalMultiplier: this._freeSpinTotalMultiplier,
    });
  };

  onSpin = (buyFreeSpin?: boolean) => {
    this._triggerSpinEvent(buyFreeSpin);
  };

  onAssetsLoaded = () => {
    this._triggerAssetsLoadedEvent();
  };

  onFallComplete = (winAmount: number, multiplier: number, outcome?: IGameOutcome) => {
    this._triggerFallCompleteEvent(winAmount, multiplier, outcome);
  };

  onSpinComplete = (tumbleWinAmount: number, totalWinAmount: number) => {
    this._triggerSpinCompleteEvent(tumbleWinAmount, totalWinAmount);
  };

  getDeviceType(): DeviceType {
    if (window.innerWidth <= 768) {
      return "mobile";
    } else if (window.innerWidth <= 1024) {
      return "tablet";
    } else {
      return "desktop";
    }
  }
}
