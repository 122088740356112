import Axios from "axios";
import { IGameInitResponse, IGameSpinResponse } from "./types";
import { IGameStat } from "../../types";
import { Game } from "../../game";

const prepareBody = (token: string) => {
  const queryParams = getQueryParams();
  return {
    platformCode: queryParams["platformcode"] || "prn",
    userCode: queryParams["usercode"] || "gg",
    brandCode: queryParams["brandcode"] || "tst1",
    channel: queryParams["channel"] || "web",
    currency: queryParams["currency"] || "eur",
    gameCode: queryParams["gamecode"] || "00001",
    token: queryParams["token"] || token,
  };
};

export const getGameInitData = async (token: string) => {
  //TODO: get baseurl from environment

  const result = (await Axios.post("https://game-engine.accamax.com/Game/Init", prepareBody(token))).data;
  return result as IGameInitResponse;
};
export const getGameStats = async (token: string) => {
  const result = (await Axios.post("https://game-engine.accamax.com/Game/Ping", prepareBody(token))).data;
  return result as { stats: IGameStat };
};
export const getGameSpin = async (game: Game, buyFreeSpins: boolean = false) => {
  const config = {
    headers: {
      symbols_test: window.testData?.testSymbols && JSON.stringify(window.testData?.testSymbols), // Your custom header
    },
  };

  const result = (
    await Axios.post(
      "https://game-engine.accamax.com/Game/Spin",
      {
        ...prepareBody(game.id),
        index: game.index,
        stake: game.stake,
        buyFreeSpins,
        anteBetEnabled: game.anteBetActive,
      },
      config
    )
  ).data;
  return result as IGameSpinResponse;
};

function getQueryParams(): Record<string, string> {
  const params = new URLSearchParams(window.location.search);
  const queryParams: Record<string, string> = {};

  // Iterate over all parameters
  params.forEach((value, key) => {
    queryParams[key] = value;
  });

  return queryParams;
}
