import { ConfigProvider, Flex, theme } from "antd";
import React, { memo, useEffect, useState } from "react";
import { Game } from "../../lib/games/game";
import styles from "./index.module.css";
import { IGameOutcome } from "../../lib/games/dede/service/types";
import { IFreeSpinStatus } from "../../lib/games/types";

const TumbleWin: React.FC<{ game: Game }> = ({ game }) => {
  const [outcomes, setOutcomes] = useState<IGameOutcome[]>([]);
  const [lastOutcome, setLastOutcome] = useState<IGameOutcome>();
  const [freeSpinStatus, setFreeSpinStatus] = useState<IFreeSpinStatus>();
  const timeoutRef = React.useRef<NodeJS.Timeout>();

  useEffect(() => {
    const listener = () => {
      setOutcomes([]);
      setLastOutcome(undefined);
    };
    game.addSpinListener(listener);

    const tumbleListener = (newOutcome: IGameOutcome) => {
      setOutcomes((prev) => [...prev, newOutcome]);
      setLastOutcome(newOutcome);
      clearTimeout(timeoutRef.current);

      timeoutRef.current = setTimeout(() => {
        setLastOutcome(undefined);
      }, 3000);
    };
    game.addTumbleListener(tumbleListener);

    const freeSpinChangeListener = (freeSpin: IFreeSpinStatus) => {
      setFreeSpinStatus(freeSpin);
    };
    game.addFreeSpinChangeListener(freeSpinChangeListener);

    return () => {
      game.removeSpinListener(listener);
      game.removeTumbleListener(tumbleListener);
      game.removeFreeSpinChangeListener(freeSpinChangeListener);
    };
  }, [game]);

  const renderTumbleWin = () =>
    outcomes?.length > 0 && (
      <div className={styles.container}>
        <div style={{ fontSize: 12 }}>Tumble Win</div>
        {lastOutcome ? (
          <Flex justify="space-between">
            <div>{lastOutcome.tumbleWinAmount}</div>
            {lastOutcome.multiplier > 1 && <div>x{lastOutcome.multiplier}</div>}
          </Flex>
        ) : (
          <Flex justify="space-between">
            <div>{game.winAmount}</div>
          </Flex>
        )}
      </div>
    );

  const renderFreeSpinWin = () => (
    <div className={styles.container}>
      <div style={{ fontSize: 12 }}>Free Spin Win</div>
      <Flex justify="space-between">
        <div>{game.freeSpinWinAmount}</div>
      </Flex>
    </div>
  );

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorPrimary: "#D29737",
        },
      }}
    >
      {freeSpinStatus?.active ? renderFreeSpinWin() : renderTumbleWin()}
    </ConfigProvider>
  );
};

export default memo(TumbleWin);
