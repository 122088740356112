import { Graphics, Sprite, Text, Texture } from "pixi.js";
import { Dede } from ".";

export class AnteBetManager {
  anteBetPopup?: Graphics;

  constructor(private game: Dede) {
    this.game.onGoldenBetClick = () => {
      this.game.onClick();
      if (this.game.anteBetActive) {
        this.game.anteBetActive = false;
      } else this.mountAnteBetPopup();
    };
  }

  mountAnteBetPopup() {
    console.log("mountAnteBetPopup");
    const graphics = new Graphics();
    const rect = graphics.rect(20, 200, window.innerWidth - 40, 200);
    rect.fill(0x9d34aa);
    rect.alpha = 1;
    const text = new Text();
    text.text = "Activate AnteBet?";
    text.style = { fill: 0xffffff, fontSize: 20 };
    text.x = window.innerWidth / 2 - text.width / 2;
    text.y = 300;
    rect.addChild(text);

    const okButton = this.initOkButton();
    graphics.addChild(okButton);

    const cancelButton = this.initCancelButton();
    graphics.addChild(cancelButton);
    this.game.app.stage.addChild(graphics);
    this.anteBetPopup = graphics;
  }

  initCancelButton() {
    const buttonTexture = Texture.from("cancelButton"); // Use any button image
    const button = new Sprite(buttonTexture);

    button.interactive = true;
    button.cursor = "pointer"; // Set cursor to pointer on hover
    button.width = 60;
    button.height = 60;
    button.anchor.set(0.5);
    button.x = window.innerWidth / 2 - 30;
    button.y = 350;
    button.zIndex = 100;

    button.on("pointerdown", () => {
      button.scale.set(button.scale.x * 0.9);
      button.tint = 0xaaaaaa;
      console.log("pointerdown!");
    });
    button.on("pointerup", () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
      this.anteBetPopup?.destroy();
    });
    button.on("pointerupoutside", () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
    });
    return button;
  }

  initOkButton() {
    const buttonTexture = Texture.from("okButton"); // Use any button image
    const button = new Sprite(buttonTexture);

    button.interactive = true;
    button.cursor = "pointer"; // Set cursor to pointer on hover
    button.width = 60;
    button.height = 60;
    button.anchor.set(0.5);
    button.x = window.innerWidth / 2 + 30;
    button.y = 350;
    button.zIndex = 100;

    button.on("pointerdown", () => {
      button.scale.set(button.scale.x * 0.9);
      button.tint = 0xaaaaaa;
      console.log("pointerdown!");
    });
    button.on("pointerup", () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
      this.game.anteBetActive = true;
      this.unmountAnteBetPopup();
    });
    button.on("pointerupoutside", () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
    });
    return button;
  }

  unmountAnteBetPopup() {
    this.anteBetPopup?.destroy();
  }
}
