import { Graphics, Sprite, Text, Texture } from "pixi.js";
import { Dede } from ".";
import { IFreeSpinStatus } from "../types";

export class BigWinManager {
  popupContainer?: Graphics;
  timeout?: NodeJS.Timeout;
  freeSpinStatus?: IFreeSpinStatus;

  constructor(private game: Dede) {
    game.addSpinCompleteListener((spinWinAmount) => {
      console.log("SpinWinAmountChanged", spinWinAmount);
      if (spinWinAmount > this.game.stake * (this.game.config?.limits.winSizes?.[2] || 20))
        this.mountPopup(spinWinAmount, "MEGA WIN");
      else if (spinWinAmount > this.game.stake * (this.game.config?.limits.winSizes?.[1] || 10))
        this.mountPopup(spinWinAmount, "SUPER WIN");
      else if (spinWinAmount > this.game.stake * (this.game.config?.limits.winSizes?.[0] || 5))
        this.mountPopup(spinWinAmount, "BIG WIN");
    });

    game.addFreeSpinChangeListener((status) => {
      this.freeSpinStatus = status;
    });
  }

  initOkButton() {
    const buttonTexture = Texture.from("okButton"); // Use any button image
    const button = new Sprite(buttonTexture);

    button.interactive = true;
    button.cursor = "pointer"; // Set cursor to pointer on hover
    button.width = 60;
    button.height = 60;
    button.anchor.set(0.5);
    button.x = window.innerWidth / 2;
    button.y = 350;
    button.zIndex = 100;

    button.on("pointerdown", () => {
      button.scale.set(button.scale.x * 0.9);
      button.tint = 0xaaaaaa;
      console.log("pointerdown!");
    });
    button.on("pointerup", () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
      this.unmountPopup();
    });
    button.on("pointerupoutside", () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
    });
    return button;
  }

  mountPopup(winAmount: number, winMessage: string) {
    this.popupContainer?.destroy();

    this.game.paused = true;
    console.log("mountbigwinUI");
    const graphics = new Graphics();
    const rect = graphics.rect(20, 200, window.innerWidth - 40, 200);
    rect.fill(0x9d34aa);
    rect.alpha = 1;
    const text = new Text();
    text.text = winMessage + "!! You won " + winAmount + " USD!";
    text.style = { fill: 0xffffff, fontSize: 20 };
    text.x = window.innerWidth / 2 - text.width / 2;
    text.y = 300;
    rect.addChild(text);
    const button = this.initOkButton();
    this.game.app.stage.addChild(graphics);
    graphics.addChild(button);
    this.popupContainer = graphics;
  }
  unmountPopup() {
    console.log("unmountbigwinUI");
    this.game.paused = false;
    this.popupContainer?.destroy();
  }
}
