import React, { useState } from "react";
import styles from "./toggleButton.module.css";

interface ToggleButtonProps {
    title: string;
    onToggle: (isOn: boolean) => void;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({ title, onToggle }) => {
    const [isOn, setIsOn] = useState<boolean>(false);

    const toggleButton = () => {
        const newState = !isOn;
        setIsOn(newState);
        onToggle(newState); // Exposing the toggle state
    };

    return (
        <div className={styles.toggleContainer}>
            <span>{title}</span>
            <div
                className={`${styles.toggleButton} ${isOn ? styles.active : ''}`}
                onClick={toggleButton}
            >
                <div className={styles.toggleThumb}></div>
            </div>
        </div>
    );
};

export default ToggleButton;
