export const dedeAssets = [
  { alias: "okButton", src: "/assets/games/dede/okButton.png" },
  { alias: "cancelButton", src: "/assets/games/dede/cancelButton.png" },
  { alias: "background", src: "/assets/games/dede/bg1.png" },
  { alias: "backgroundFreeSpin", src: "/assets/games/dede/bg2.jpeg" },
  { alias: "multiplier", src: "/assets/games/dede/multiplier.png" },
  { alias: "spineboyData", src: "/spineboy-pro.json" },
  { alias: "spineboyAtlas", src: "/spineboy-pma.atlas" },
  { alias: "backgroundMusic", src: "/assets/games/dede/background.mp3", loadLazy: true },
  { alias: "explodeMusic", src: "/assets/games/dede/explode.mp3", loadLazy: true },
  { alias: "landingMusic", src: "/assets/games/dede/landing.mp3", loadLazy: true },
  { alias: "selectMusic", src: "/assets/games/dede/sword.mp3", loadLazy: true },
  { alias: "clickMusic", src: "/assets/games/dede/click.mp3", loadLazy: true },
  { alias: "s1", src: "/assets/games/dede/s1.png" },
  { alias: "s2", src: "/assets/games/dede/s2.png" },
  { alias: "s3", src: "/assets/games/dede/s3.png" },
  { alias: "s4", src: "/assets/games/dede/s4.png" },
  { alias: "s5", src: "/assets/games/dede/s5.png" },
  { alias: "s6", src: "/assets/games/dede/s6.png" },
  { alias: "s7", src: "/assets/games/dede/s7.png" },
  { alias: "s8", src: "/assets/games/dede/s8.png" },
  { alias: "s9", src: "/assets/games/dede/s9.png" },
  { alias: "s10", src: "/assets/games/dede/s10.png" },
  { alias: "startButton", src: "/assets/games/dede/start_button.png" },
  { alias: "symbolSelect", src: "/assets/games/dede/select.png" },
  { alias: "explodeAnimation", src: "/assets/games/dede/mc.json" },
];
