import { Graphics, Sprite, Text, Texture } from "pixi.js";
import { Dede } from ".";

export class AfterLoadManager {
  popup?: Graphics;
  onOkClick?: () => void;

  constructor(private game: Dede) {}

  mountAfterLoadScreen(onOkClick?: () => void) {
    this.onOkClick = onOkClick;
    console.log("mountAfterLoadScreen");
    const graphics = new Graphics();
    const rect = graphics.rect(0, 0, window.innerWidth, window.innerHeight);
    rect.fill(0x9d34aa);
    rect.alpha = 1;
    const text = new Text();
    text.text = "Start Game?";
    text.style = { fill: 0xffffff, fontSize: 20 };
    text.x = window.innerWidth / 2 - text.width / 2;
    text.y = 300;
    rect.addChild(text);

    const okButton = this.initOkButton();
    graphics.addChild(okButton);

    const cancelButton = this.initCancelButton();
    graphics.addChild(cancelButton);
    this.game.app.stage.addChild(graphics);
    this.popup = graphics;
  }

  initCancelButton() {
    const buttonTexture = Texture.from("cancelButton"); // Use any button image
    const button = new Sprite(buttonTexture);

    button.interactive = true;
    button.cursor = "pointer"; // Set cursor to pointer on hover
    button.width = 60;
    button.height = 60;
    button.anchor.set(0.5);
    button.x = window.innerWidth / 2 - 30;
    button.y = 350;
    button.zIndex = 100;

    button.on("pointerdown", () => {
      button.scale.set(button.scale.x * 0.9);
      button.tint = 0xaaaaaa;
      console.log("pointerdown!");
    });
    button.on("pointerup", () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
      this.popup?.destroy();
    });
    button.on("pointerupoutside", () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
    });
    return button;
  }

  initOkButton() {
    const buttonTexture = Texture.from("okButton"); // Use any button image
    const button = new Sprite(buttonTexture);

    button.interactive = true;
    button.cursor = "pointer"; // Set cursor to pointer on hover
    button.width = 60;
    button.height = 60;
    button.anchor.set(0.5);
    button.x = window.innerWidth / 2 + 30;
    button.y = 350;
    button.zIndex = 100;

    button.on("pointerdown", () => {
      button.scale.set(button.scale.x * 0.9);
      button.tint = 0xaaaaaa;
      console.log("pointerdown!");
    });
    button.on("pointerup", () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
      this.onOkClick?.();
      this.unmountAfterLoadPopup();
    });
    button.on("pointerupoutside", () => {
      button.scale.set(button.scale.x / 0.9);
      button.tint = 0xffffff;
    });
    return button;
  }

  unmountAfterLoadPopup() {
    this.popup?.destroy();
  }
}
