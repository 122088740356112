import { Application, Ticker, Container, Sprite, Texture } from "pixi.js";

import {
  REELS_COUNT,
  SYMBOLS_PER_REEL,
  SYMBOL_GAP,
  SYMBOL_WIDTH_MOBILE,
  SYMBOL_HEIGHT_MOBILE,
} from "./resources/constants";
import { Game } from "../game";
import { DeviceType, IFreeSpinStatus } from "../types";
import { ReelsManager } from "./ReelsManager";
import { MainCharacter } from "./models/mainCharacter";
import { ServiceManager } from "./serviceManager";
import { IGameOutcome } from "./service/types";
import { SoundManager } from "./soundManager";
import { AssetLoader } from "../assetLoader";
import { dedeAssets } from "./resources/assets";
import { FreeSpinManager } from "./FreeSpinManager";
import { BigWinManager } from "./BigWinManager";
import { AnteBetManager } from "./AnteBetManager";
import { AfterLoadManager } from "./AfterLoadManager";

export class Dede extends Game {
  app: Application;
  public soundManager: SoundManager;
  public reelsManager: ReelsManager;
  private mainCharacter: MainCharacter;
  private afterLoadManager: AfterLoadManager;
  public serviceManager: ServiceManager;
  public assetLoader: AssetLoader;
  public freeSpinManager: FreeSpinManager;
  public anteBetManager: AnteBetManager;
  public bigWinManager: BigWinManager;
  assetsLoaded = false;
  lazyAssetsLoaded = false;
  symbolWidth = 0;
  reelsEndPosition = 0;
  symbolHeight = 0;
  reelsContainer: Container;
  outcomes: IGameOutcome[] = [];
  currentOutcome?: IGameOutcome;
  prevOutcome?: IGameOutcome;
  background?: Sprite;

  constructor() {
    super();
    this.app = new Application();
    this.reelsContainer = new Container();
    this.serviceManager = new ServiceManager(this);
    this.soundManager = new SoundManager(this);
    this.freeSpinManager = new FreeSpinManager(this);
    this.anteBetManager = new AnteBetManager(this);
    this.bigWinManager = new BigWinManager(this);
    this.afterLoadManager = new AfterLoadManager(this);
    this.assetLoader = new AssetLoader(dedeAssets);
    this.reelsManager = new ReelsManager(
      this,
      0,
      10,
      {
        onDestroy: () => {},
        onFallComplete: async (multiplier, outcome) => {
          console.log("Fall complete", multiplier, outcome);
          const tumbleWinAmount = outcome?.tumbleWinAmount || 0;
          const totalWinAmount = outcome?.totalWinAmount || 0;

          this.spinWinAmount = tumbleWinAmount;
          if (this.freeSpinActive) {
            this.freeSpinTotalMultiplier = multiplier;
          }

          if (outcome?.isLastOutcome) {
            this.isRunning = false;
            if (outcome.isFreeSpin ? outcome.isLastFreeSpinOutcome : true) {
              this.balance += this.winAmount;
            }
            if (outcome.isFreeSpin) {
              this.freeSpinWinAmount = totalWinAmount;
            }
            this.winAmount = totalWinAmount;
          } else {
            if (this.initialSpinDone) this.isRunning = true;
            else {
              this.initialSpinDone = true;
              this.isRunning = false;
            }
          }
          const historyItem = {
            outcome,
            tumbleWinAmount,
            totalWinAmount,
            multiplier,
            spinCompleted: !!outcome?.isLastOutcome,
          };
          this.history = [...this.history, historyItem];
          if (outcome ? outcome.isLastOutcome : true) {
            this.onSpinComplete(tumbleWinAmount, totalWinAmount);
            console.log("Spin complete", this.paused);
            // await new Promise((resolve) => setTimeout(resolve, 100));
            // while (this.paused) {
            //   await new Promise((resolve) => setTimeout(resolve, 100));
            // }
          }

          this.onFallComplete(totalWinAmount, multiplier, outcome);
          if (!this.isRunning && this.autoPlayCount) {
            this.runReels();
          }
        },
      },
      this.reelsContainer
    );
    this.addDeviceTypeChangeListener(this.onResize);
    this.onResize(this.getDeviceType());
    this.isRunning = true;

    this.mainCharacter = new MainCharacter(this);
    window.game = this;
  }

  async load(onProgress: (progress: number) => void) {
    await this.assetLoader.load(
      (progress) => {
        if (progress === 1) {
          if (!this.assetsLoaded) {
            this.onAssetsLoaded();
            this.assetsLoaded = true;
          }
        }
        onProgress(progress);
      },
      (progress) => {
        if (progress === 1) {
          this.lazyAssetsLoaded = true;
          this.soundManager.onAssetsLoaded();
        }
      }
    );
  }

  async mount() {
    await this.app.init({ background: "#39173A", resizeTo: window });
    const bg = new Sprite(Texture.from("background"));
    bg.width = window.innerWidth;
    bg.height = window.innerHeight;
    bg.scale.x = bg.scale.y = Math.max(this.app.screen.width / bg.width, this.app.screen.height / bg.height);
    this.app.stage.addChild(bg);
    this.background = bg;
    await this.serviceManager.initGame();

    const gameContainer = document.getElementById("gameContainer");
    if (!gameContainer) return false;
    gameContainer.appendChild(this.app.canvas);
    window.__PIXI_APP__ = this.app;

    this.afterLoadManager.mountAfterLoadScreen(async () => {
      this.reelsManager.mount();
      this.app.ticker.add((ticker) => this.update(ticker));
      await this.mainCharacter.mount();
      this.onMountDone();
    });
  }

  update(ticker: Ticker) {
    this.reelsManager.update(ticker);
  }

  async runReels(buyFreeSpins: boolean = false) {
    console.log("Running reels");
    this.onSpin();
    this.winAmount = 0;
    this.spinWinAmount = 0;
    this.isRunning = true;
    if (this.autoPlayCount > 0) {
      this.autoPlayCount--;
    }
    this.history = [];
    const spendAmount = buyFreeSpins ? this.stake * this.config.freeSpinBuyMultiplier : this.stake;
    if (this.balance < spendAmount) {
      if (this.autoPlayCount) {
        this.autoPlayCount = 0;
      }
      return;
    }
    this.balance -= spendAmount;
    this.reelsManager.runReels(buyFreeSpins);
  }

  async simulate(response: string) {
    this.serviceManager.loadSimulateResponse(response);
    await this.runReels();
  }

  async runFreeSpinReels(outcomes: IGameOutcome[]) {
    console.log("runFreeSpinReels");
    this.onSpin();
    this.isRunning = true;
    this.spinWinAmount = 0;
    this.history = [];
    this.reelsManager.runReelsWithOutcomes(outcomes);
    this.background!.texture = Texture.from("backgroundFreeSpin");

    await new Promise((resolve) => setTimeout(resolve, 0));
    const freeSpinEndListener = (freeSpinStatus: IFreeSpinStatus) => {
      if (!freeSpinStatus.active) {
        this.removeFreeSpinChangeListener(freeSpinEndListener);
        this.background!.texture = Texture.from("background");
      }
    };
    this.addFreeSpinChangeListener(freeSpinEndListener);
  }

  onResize = (deviceType: DeviceType) => {
    this.symbolWidth = SYMBOL_WIDTH_MOBILE;
    this.symbolHeight = SYMBOL_HEIGHT_MOBILE;
    this.reelsContainer.x = (window.innerWidth - (this.symbolWidth + SYMBOL_GAP) * REELS_COUNT) / 2;
    this.reelsEndPosition =
      (window.innerHeight - (this.symbolHeight + SYMBOL_GAP) * SYMBOLS_PER_REEL) / 2 +
      (this.symbolHeight + SYMBOL_GAP) * (SYMBOLS_PER_REEL - 1);
  };
}
