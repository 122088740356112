import React, { useState, ChangeEvent, useRef, memo } from "react";
import { SymbolHelper } from "./symbolHelper";
import ToggleButton from "./toggleButton";
import { Button, Modal } from "antd";

export type Message = {
  type: string;
  payload?: any;
  anteBetEnabled?: boolean;
};

const TestHarness: React.FC = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [width, setWidth] = useState<number>(400);
  const [height, setHeight] = useState<number>(800);
  const [url, setUrl] = useState<string>("");
  const [text, setText] = useState<string>(""); // State for multiline textbox
  const [key, setKey] = useState<number>(0); // This key is used to force reloading the iframe
  const [isAnteBet, setIsAnteBet] = useState<boolean>(false);
  const responseInputRef = useRef<string>("");

  React.useEffect(() => {
    const { protocol, hostname, port } = window.location;
    const fullQualifiedDomainName = `${protocol}//${hostname}${port ? `:${port}` : ""}`;
    setUrl(
      fullQualifiedDomainName +
        `?platformcode=prn&usercode=gg&brandcode=tst1&channel=web&currency=eur&gamecode=00001&token=${getRandomToken()}`
    );
  }, []);

  const getRandomToken = (): number => {
    return Math.floor(Math.random() * (100000 - 80000 + 1)) + 80000;
  };

  const handleWidthChange = (e: ChangeEvent<HTMLInputElement>) => {
    setWidth(Number(e.target.value));
    setKey((prevKey) => prevKey + 1); // Update the key to reload the iframe
  };

  const handleHeightChange = (e: ChangeEvent<HTMLInputElement>) => {
    setHeight(Number(e.target.value));
    setKey((prevKey) => prevKey + 1); // Update the key to reload the iframe
  };

  const handleTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
  };

  const handleUrlChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.value);
    setKey((prevKey) => prevKey + 1); // Update the key to reload the iframe
  };

  const handleSymbolButtonClicked = (symbolType: number) => {
    const symbolHelper = new SymbolHelper(symbolType, 5, 6);
    const symbols = symbolHelper.generateSymbols();

    setText(JSON.stringify(symbols));
  };

  const handleAnteBetToggle = (isOn: boolean) => {
    setIsAnteBet(isOn);
  };

  const handleSubmit = () => {
    if (iframeRef.current) {
      const message: Message = {
        type: "testharness.spin",
        payload: text,
      };
      // Post the message to the iframe
      iframeRef.current.contentWindow?.postMessage(message, "*");
    }
  };

  const buyFreeSpin = () => {
    if (iframeRef.current) {
      const message: Message = {
        type: "testharness.buyFreeSpin",
        payload: text,
      };
      // Post the message to the iframe
      iframeRef.current.contentWindow?.postMessage(message, "*");
    }
  };

  const simulateSpin = () => {
    if (!iframeRef.current) return;
    Modal.confirm({
      title: "Simulate Spin",
      content: (
        <div>
          <p>Enter the response you want to simulate.</p>
          <textarea
            onChange={(e) => {
              responseInputRef.current = e.target.value;
            }}
            rows={10}
            style={{ width: "100%" }}
          />
        </div>
      ),
      onOk: () => {
        if (!iframeRef.current) return;
        const message: Message = {
          type: "testharness.simulateSpin",
          payload: responseInputRef.current,
        };
        // Post the message to the iframe
        iframeRef.current.contentWindow?.postMessage(message, "*");
      },
    });
  };

  const handleNormalSpin = () => {
    if (iframeRef.current) {
      const message: Message = {
        type: "testharness.spin",
        anteBetEnabled: isAnteBet,
      };
      // Post the message to the iframe
      iframeRef.current.contentWindow?.postMessage(message, "*");
    }
  };

  if (url === "") return <></>;

  return (
    <>
      <div style={{ margin: "10px", width: "50%" }}>
        <label>
          URL:
          <input type="text" value={url} onChange={handleUrlChange} style={{ width: "100%" }} />
        </label>
      </div>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        <div style={{ flex: "1", padding: "10px" }}>
          <h1>Testharness</h1>

          <div style={{ marginBottom: "10px" }}>
            <label>
              Width:
              <input type="number" value={width} onChange={handleWidthChange} style={{ width: "100%" }} />
            </label>
          </div>
          <div>
            <label>
              Height:
              <input type="number" value={height} onChange={handleHeightChange} style={{ width: "100%" }} />
            </label>
          </div>
          <div style={{ marginBottom: "10px", marginTop: "10px" }}>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
              <Button onClick={() => handleSymbolButtonClicked(1)} style={{ flex: "1 1 30%", padding: "10px" }}>
                Random
              </Button>
              <Button onClick={() => handleSymbolButtonClicked(2)} style={{ flex: "1 1 30%", padding: "10px" }}>
                1 Symbol Win
              </Button>
              <Button onClick={() => handleSymbolButtonClicked(3)} style={{ flex: "1 1 30%", padding: "10px" }}>
                2 Symbol Win
              </Button>
              <Button onClick={() => handleSymbolButtonClicked(4)} style={{ flex: "1 1 30%", padding: "10px" }}>
                1 Multiplier
              </Button>
              <Button onClick={() => handleSymbolButtonClicked(5)} style={{ flex: "1 1 30%", padding: "10px" }}>
                Scatter Win
              </Button>
              <Button onClick={() => handleSymbolButtonClicked(6)} style={{ flex: "1 1 30%", padding: "10px" }}>
                Button 6
              </Button>
            </div>
            <div style={{ marginBottom: "10px", marginTop: "10px" }}>
              <label>
                Symbols:
                <textarea value={text} onChange={handleTextChange} rows={10} style={{ width: "100%" }} />
              </label>
            </div>
            <div style={{ marginBottom: "10px", marginTop: "10px" }}>
              <ToggleButton title="AnteBet" onToggle={handleAnteBetToggle} />
            </div>
            <Button onClick={handleNormalSpin} style={{ padding: "10px", width: "100%" }}>
              Normal Spin
            </Button>
            <Button onClick={handleSubmit} style={{ padding: "10px", width: "100%" }}>
              Submit Symbols
            </Button>
            <Button onClick={buyFreeSpin} style={{ padding: "10px", width: "100%" }}>
              Buy Freespin
            </Button>
            <Button onClick={simulateSpin} style={{ padding: "10px", width: "100%" }}>
              Simulate Spin
            </Button>
          </div>
        </div>
        <div style={{ flex: "4", padding: "10px" }}>
          <iframe
            ref={iframeRef}
            key={key}
            src={url}
            title="Game Frame"
            style={{
              border: "1px solid #000",
              display: "block",
              overflow: "hidden",
              width: width,
              height: height,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default memo(TestHarness);
